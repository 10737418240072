/* eslint-disable @next/next/next-script-for-ga */
import { CustomThemeProvider } from '@/components/context';
import '@/styles/GlobalStyles.css';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <CustomThemeProvider>
      <Head>
        <title>Mevrik Web Chat | AI-powered Omni-channel Platform for Digital CX, Engagement & Commerce</title>
        <meta name="title" content="Redefine Your Digital Customer Experience in Omni-Channel" />
        <meta
          name="description"
          content="Mevrik DCX Platform can help you to increase your Sales, Marketing & Delights your Customer Support with the Help of AI."
        />
        <meta
          name="keywords"
          content="DCX, Digital Customer Experience, AI Chatbot, LiveChat, Social Media, Email, Video Chat, Knowledgebase, Sales Forecasting, AI Based Segmentation, E-Commerce Chatbot, Telecom Chatbot, Banking Chatbot, Web Widget, Messaging SDK, Mobile Chat, In-App Chat"
        />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="1 days" />
        <meta name="author" content="Gadiel Myron" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://mevrik.com/" />
        <meta property="og:title" content="Redefine Your Digital Customer Experience in Omni-Channel" />
        <meta
          property="og:description"
          content="Mevrik DCX Platform can help you to increase your Sales, Marketing & Delights your Customer Support with the Help of AI."
        />
        <meta property="og:image" content="/meta-og-image.jpg" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://mevrik.com/" />
        <meta property="twitter:title" content="Redefine Your Digital Customer Experience in Omni-Channel" />
        <meta
          property="twitter:description"
          content="Mevrik DCX Platform can help you to increase your Sales, Marketing & Delights your Customer Support with the Help of AI."
        />
        <meta property="twitter:image" content="/meta-og-image.jpg" />
      </Head>

      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KJLP9J2');
      `}
      </Script>
      <Component {...pageProps} />
    </CustomThemeProvider>
  );
}

export default MyApp;
