import { defaultTheme } from '@/config';
import { createContext, FC, ReactNode, useCallback, useContext, useState } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomThemeContext = createContext((data: IAPI.UserSessionResponse) =>
  console.error('CustomThemeContext is not initialized'),
);

export const CustomThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [theme, setTheme] = useState<DefaultTheme>(defaultTheme);

  const updateThemeFromAPI = useCallback((data: IAPI.UserSessionResponse) => {
    const {
      persona: { color, get_started_button_color, header_title_bg_color },
    } = data;
    setTheme((v) => ({
      ...v,
      colors: {
        ...v.colors,
        primary: color,
        getStartedBtn: get_started_button_color,
        headerBg: header_title_bg_color,
      },
    }));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CustomThemeContext.Provider value={updateThemeFromAPI}>{children}</CustomThemeContext.Provider>
    </ThemeProvider>
  );
};

export const useCustomThemeContext = () => useContext(CustomThemeContext);
