export * from './theme';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  apiURL: process.env.NEXT_PUBLIC_API_URL as string,
  localApiURL: process.env.NEXT_PUBLIC_LOCAL_API_URL as string,
  defaultChannel: process.env.NEXT_PUBLIC_DEFAULT_CHANNEL as string,
  broadcastDriver: process.env.NEXT_PUBLIC_BROADCAST_DRIVER as string,
  pusherAHost: process.env.NEXT_PUBLIC_PUSHER_HOST as string,
  pusherAppId: process.env.NEXT_PUBLIC_PUSHER_APP_ID as string,
  pusherKey: process.env.NEXT_PUBLIC_PUSHER_KEY as string,
  pusherSecret: process.env.NEXT_PUBLIC_PUSHER_SECRET as string,
  pusherCluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER as string,
  dev: process.env.NODE_ENV === 'development',
};
