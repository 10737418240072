import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  font: {
    family: '"Source Sans Pro", sans-serif',
    size: '14px',
  },
  colors: {
    bg: '#e5e5e5',
    text: '#4f4e4e',
    primary: '#F6001D',
    secondary: '#a3a3a3',
    light: '#f5f5f5',
    success: '#1ad743',
    danger: '#d87675',
    white: '#ffffff',
    black: '#121F3E',
    getStartedBtn: '#F6001D',
    customerChat: '#F6001D',
    headerBg: 'glass',
  },
  border: {
    radius: '6px',
  },
};
